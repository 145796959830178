import { createTheme } from '@mui/material/styles';

export const DefaultDarkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#051441',
      paper: '#051441',
    },
    common: {
      white: '#fff',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#051441',
    },
    primary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#051441',
    },
    secondary: {
      light: '#505b7a',
      main: '#051441',
      dark: '#05123b',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '3.75rem',
        },
        body1: {
          fontSize: '1.2rem',
        },
        h3: {
          fontWeight: 500,
        },
        h4: {
          fontWeight: 500,
          fontSize: '2.5rem',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
      },
    },
  },
});

//Copied from: https://mui.com/material-ui/customization/typography/#responsive-font-sizes
DefaultDarkTheme.components.MuiTypography.styleOverrides.h1 = {
  fontSize: '3.75rem',
  [DefaultDarkTheme.breakpoints.down('md')]: {
    fontSize: '2.75rem',
  },
};
