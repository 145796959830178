// eslint-disable-next-line import/no-anonymous-default-export
export default {
  url: 'https://www.appcenternederland.nl',
  openGraph: {
    type: 'website',
    locale: 'nl_NL',
    url: 'https://www.appcenternederland.nl',
    site_name: 'AppCenter Nederland',
  },
};
