import React, { useEffect, useState } from 'react';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import createEmotionCache from '../utils/createEmotionCache';
import { DefaultDarkTheme } from '../themes/default-dark.theme';
import SEO from '../next-seo.config';
import { DefaultSeo } from 'next-seo';
import Script from 'next/script';

const clientSideEmotionCache = createEmotionCache();

const LandingPagesApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  const [userAgent, setUserAgent] = useState<string>('');
  const GTM_ID = 'GTM-MZKKH8Z';

  const pageSpeedMobileUserAgent = 'Chrome-Lighthouse';
  const pageSpeedDesktopUserAgent = 'Google Page Speed Insights';

  // const getUserAgent = useCallback(() => {
  //   return window.navigator.userAgent;
  // }, []);

  // const userAgent = getUserAgent();
  //
  // console.log(userAgent);

  useEffect(() => {
    if (window.navigator.userAgent) {
      setUserAgent(window.navigator.userAgent);
    }
  }, []);

  const isGooglePageSpeed =
    userAgent.includes(pageSpeedMobileUserAgent) ||
    userAgent.includes(pageSpeedDesktopUserAgent);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={DefaultDarkTheme}>
        <CssBaseline />
        <DefaultSeo {...SEO} />
        {process.env.NODE_ENV === 'production' && !isGooglePageSpeed && (
          <Script id="google-tag-manager" strategy="lazyOnload">
            {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `}
          </Script>
        )}
        <Component {...pageProps} />
      </ThemeProvider>
    </CacheProvider>
  );
};

export default LandingPagesApp;
